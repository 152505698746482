
.forgotpass-wrapper, .resetpass-wrapper{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/images/login-bg.jpg');
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 768px){
        display: block;
        background-image: none;
        background-color: white;
        padding-top: 50px;
    }
    &:after{
        position: fixed;
        content: '';
        height:100vh;
        width: 100vw;
        background-color: rgba(255, 255, 255, 0.98);
        z-index: 1;
        @media screen and (max-width: 768px){
            display: none;
        }
    }

    .content{
        position: relative;
        z-index: 2;
        background-color: white;
        border-radius: 5px;
        @media screen and (max-width: 768px){
            border: none !important;
            box-shadow: none;
        }
        .logo{
            padding: 30px;
            text-align: center;
            h1{
                font-size: 25px;
                font-weight: bold;
            }
            img{
                width: 150px;
                height: 150px;
            }
        }
        .loader-view{
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
}

