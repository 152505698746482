body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    font-weight: 400;
    color: #5c5c5c !important;
    background-color: rgb(245, 245, 245);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}



.pagination-content{
    height: 57px;
    padding: 10px 0;
    text-align: center;
    ul{
        margin: 0;
        padding: 0;
        justify-content: flex-end;
        li{
            flex-grow: 1;
            border: 1px solid #e2e2e2;
            height: 40px;
            max-width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 2px;
            border-radius: 3px;
            background: white;
            &.active{
                background: #3f51b5;
                a{
                    color: white !important
                }
            }
            a{
                outline: none;
                color: black;
                text-decoration: none;
            }
        }
    }
}


// typograph

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 400;
    margin: 0 0 12px;
    color: inherit;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 18px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 12px;
}


@media screen and (max-width: 991px) {

    h1,
    .h1 {
        font-size: 18px;
    }

    h2,
    .h2 {
        font-size: 16px;
    }

    h3,
    .h3 {
        font-size: 15px;
    }
}

// form control
input,
textarea {
    &::placeholder {
        font-size: 13px !important;
    }

    &:focus,
    &:active,
    &:hover {
        outline: none;
        box-shadow: none;
    }
}

button{
    &:focus{
        outline: none;
    }
}

.btn {
    font-size: 14px;
    margin-bottom: 6px;
    box-shadow: none !important;

    &.btn-lg {
        font-size: 16px;
        height: 42px;
        line-height: 16px;
    }

    &.btn-outline-primary {
        background-color: transparent;
        color: $primary;

        &:hover {
            background-color: $primary;
            color: white;
        }

        &:focus {
            background-color: transparent;
            color: $primary;
            border-color: $primary;
        }
    }

    &.btn-outline-secondary {
        background-color: transparent;
        color: $secondary;

        &:hover {
            background-color: $secondary;
            color: white;
        }

        &:focus {
            background-color: transparent;
            color: $secondary;
            border-color: $secondary;
        }
    }

    &.btn-outline-danger {
        background-color: transparent;
        color: $danger;

        &:hover {
            background-color: $danger;
            color: white;
        }

        &:focus {
            background-color: transparent;
            color: $danger;
            border-color: $danger;
        }
    }

    &:not(:last-child) {
        margin-right: 15px;
    }
}

.btn-group {
    .btn:not(:last-child) {
        margin-right: 0;
    }
}


.bg-teal {
    background-color: #009688;
}

.bg-blue {
    background-color: #2196F3;
}

.bg-yellow {
    background-color: #ffd30f;
}

.bg-green-yellow {
    background-color: #c2c500;
}

.bg-brown {
    background-color: #914d0e;
}

.bg-white_red{
    background-color: #ffd5b8;
}

.pointer {
    cursor: pointer;
}

.card {
    border-radius: 8px;
    border: 1px solid #dee2e6;
    margin-bottom: 1.5rem;
}

.modal-dialog {
    min-width: 750px;
    width: 100%;

    @media screen and(max-width: 750px) {
        min-width: 100%;
    }
}

/*
* Sweet Alert
*/

.swal-button {
    background-color: #3f51b5 !important
}

.swal-button.swal-button--cancel {
    background-color: transparent !important;
    border: 1px solid #3f51b5 !important;
}

.swal-text {
    text-align: center !important;
}

.swal2-styled.swal2-confirm {
    background-color: #3f51b5 !important
}

.swal2-styled.swal2-cancel {
    background-color: transparent !important;
    border: 1px solid #3f51b5 !important;
    color: #3f51b5 !important;
}


/*
* Modal
*/

.modal-title {
    font-size: 16px;
    font-weight: bold;
}

.custom-control-label{
    &::before{
        top: 0.15rem;
    }
    &::after{
        top: 0.15rem;
    }
}


.linkbtn{
    cursor: pointer;
    font-weight: bold;
    text-decoration: #3f51b5;
}

table td{
    .view{
        &:hover{
            color: #3f51b5;
        }
    }
    .download{
        &:hover{
            color: #E91E63;
        }
    }
}